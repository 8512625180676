import React, {useEffect, useState, useCallback} from "react"
import { graphql } from "gatsby"
// import BugClick from "../components/bugclick/index"
import "./growth.scss"
import gsap from 'gsap/dist/gsap';
import randomPage from "../components/randompage.js";
import MotionPathPlugin from 'gsap/dist/MotionPathPlugin';
gsap.registerPlugin(MotionPathPlugin);
gsap.ticker.lagSmoothing(false);
let bugTypes = ["beetle","centipede","cockroach","babyroach","housefly","ladybug","moth","spider","waterbug"]
const pickBug = (amt=1) => {
  let chosen = [];
  for (var i = 0; i < amt; i++) {
    chosen.push(bugTypes[Math.floor(Math.random()*bugTypes.length)])
  }
  return chosen;
}

//This takes list of bug names and spits out actual animating bugs u made!
const Bugs = ({bugList,urls}) => {
  return (
  <div id="bug-wrapper-inner">
    <div id="bug-jar" className="bugs">
      {bugList.map((b,i) =>
        <div key={i} data-bug-name={b} className={`bug ${b}`}>
          <div className="tf-layer">
            <span className="bug-link">
              <a onClick={ e => randomPage(e,"growth",urls)} href={"/growth"+urls[i%urls.length]}></a>
            </span>
          </div>
        </div>
      )}
    </div>
</div>
  )
}

const GrowthPage = ({data}) => {
  const bugpages = data.bugpages.edges.map(e => e.node.fields.slug)
  const [bugList,setBugList] = useState([])
  const [started,setStarted] = useState(false)
  const addBug = (bug) => {
    setBugList((prevState) => {
      let newList = [...prevState]
      if(typeof bug === "object")
        newList = newList.concat(bug)
      else
         newList.push(bug)
        // console.log("adding ",newList)
      return newList
    })
  }

  const queueBugs = useCallback(() => {
    return setTimeout(() => {
      addBug(pickBug())
      queueBugs();
    },1000 + Math.floor(Math.random()*4000))
  },[bugList])

const moveQueuedBugs = () => {
  var bugs = document.querySelectorAll("#bug-jar .bug:not(.animated)");
  for (var i = 0; i < bugs.length; i++) {
    const bug = bugs[i];
    if(!bug) return;
    let name = bug.getAttribute("data-bug-name")
    let paths = document.querySelector( name === "moth" || name === "housefly" ? `#${name}` : `#bugs`);
    let chosenPath = paths.children[Math.floor(Math.random()*paths.children.length)];
    let convertedPath = MotionPathPlugin.convertToPath(chosenPath);
    const tl = gsap.timeline();

    let landed = false;

    tl.to(bug, {
      motionPath: {
        path: convertedPath[0],
        align: convertedPath[0],
        alignOrigin: [0.5,0.5],
        autoRotate: true,
        start: bugs.length > 1 ? Math.random() : 0 //Start initial bugs on page
      },
      transformOrigin: '50% 50%',
      duration: 10 + Math.random()*8,
      ease:'none',
      onComplete: function(){
        bug.classList.add("finished")
        landed = false
        // bug.remove() // testing to see if performance was any better, inconclusive -SM
      },
      // onUpdate: function(){
      //   let touchdown = Math.random() * (0.67 - 0.33) + 0.33;
      //   let thistime = this;
      //   let downtime = 0;
      //   if(bug.getAttribute("data-bug-name") === "housefly"){
      //     if(thistime.progress() > touchdown && landed === false){
      //       touchdown = 1
      //       // console.log("landed")
      //       this.pause()
      //       landed = true
      //       bug.classList.add("landed")
      //       setTimeout(function(){
      //         bug.classList.remove("landed")
      //         thistime.resume()
      //       }, 500 + Math.floor(Math.random()*2000) )
      //     }
      //   }
      // }
    });
    bug.classList.add("animated")
    // console.log(`Animate bug ${name} with path ${chosenPath.id} now!!!`);
  }

}

  useEffect(e => {
    const timer = queueBugs();
    if(typeof window !== "undefined"){
      //This is the bug element!!!
      if(!started){
        addBug(pickBug(15))
        setStarted(true)
      }
      moveQueuedBugs();
    }
    return () => clearInterval(timer);
  },[queueBugs, started])
  console.log(bugpages)
  return(
    <div id="bug-wrapper">
    <Bugs bugList={bugList} urls={bugpages}/>
      <div id="bugpaths">
        <div className="paths-inner">
          <svg x="0px" y="0px" viewBox="0 0 300 300">
            <g id="bugs">
              <polyline id="path30" className="st0" points="300 66 169 85 80 218 0 192"/>
              <polyline id="path29" className="st0" points="156 0 254 120 120 186 70 300"/>
              <polyline id="path28" className="st0" points="241 300 230 181 77 148 0 162"/>
              <polyline id="path27" className="st0" points="245 0 170 130 0 184"/>
              <polyline id="path26" className="st0" points="159 0 126 188 0 275"/>
              <polyline id="path25" className="st0" points="300 299 213 134 180 97 209 0"/>
              <polyline id="path24" className="st0" points="0 158 110 166 143 150 227 168 300 145"/>
              <polyline id="path23" className="st0" points="0 180 71 210 244 105 300 130"/>
              <polyline id="path22" className="st0" points="140 0 170 114 160 145 300 232"/>
              <line id="path21" className="st0" x1="273" y1="0" x2="0" y2="250"/>
              <line id="path20" className="st0" x1="300" y1="90" x2="0" y2="166"/>
              <polyline id="path19" className="st0" points="300 180 160 136 170 0"/>
              <polyline id="path18" className="st0" points="300 54 175 100 81 197 72 300"/>
              <polyline id="path17" className="st0" points="2 300 138 177 300 150"/>
              <line id="path16" className="st0" x1="270" y1="300" x2="200" y2="0"/>
              <line id="path15" className="st0" x1="300" y1="117" x2="0" y2="156"/>
              <line id="path14" className="st0" x1="250" y1="0" x2="210" y2="300"/>
              <line id="path13" className="st0" x1="0" y1="170" x2="300" y2="170"/>
              <line id="path12" className="st0" x1="300" y1="104" x2="0" y2="208"/>
              <line id="path11" className="st0" x1="0" y1="220" x2="300" y2="240"/>
              <line id="path10" className="st0" x1="188" y1="0" x2="92" y2="300"/>
              <line id="path9" className="st0" x1="162" y1="0" x2="128" y2="300"/>
              <polyline id="path8" className="st0" points="110 0 170 120 300 140"/>
              <polyline id="path7" className="st0" points="300 220 150 150 0 160"/>
              <line id="path6" className="st0" x1="300" y1="0" x2="30" y2="300"/>
              <line id="path5" className="st0" x1="0" y1="60" x2="154" y2="300"/>
              <polyline id="path4" className="st0" points="300 200 195 163 67 188 0 105"/>
              <line id="path3" className="st0" x1="0" y1="200" x2="300" y2="124"/>
              <line id="path2" className="st0" x1="0" y1="140" x2="300" y2="154"/>
              <line id="path1" className="st0" x1="0" y1="212" x2="300" y2="80"/>
            </g>
            <g id="moth">
              <path id="moth6" className="st1" d="M18,300c0,0,55-140,149-184S262,0,262,0"/>
              <line id="moth5" className="st1" x1="90" y1="0" x2="120" y2="300"/>
              <line id="moth4" className="st1" x1="0" y1="222" x2="300" y2="150"/>
              <path id="moth3" className="st1" d="M0,78.37c0,0,124.5,13.13,191.5,68.13s108.5,70,108.5,70"/>
              <line id="moth2" className="st1" x1="269" y1="300" x2="0" y2="132"/>
              <line id="moth1" className="st1" x1="234" y1="0" x2="168" y2="300"/>
            </g>
            <g id="housefly">
              <path id="fly8" className="st2" d="M0,229.66c0,0,6,7.34,15,12.34s38.35,11,56-1s-25.82-55.18,0-81s94-51,122-17l-7,1l2-4
                c0,0-7-45,38-34s74,83,74,83"/>
              <path id="fly7" className="st2" d="M300,56c0,0-41,31-48,33s-74-35-85-33s21,27,18,30s-87,28-102,17s-28,21-28,21l-5,6
                c0,0-23.5,18.01-50-14"/>
              <path id="fly6" className="st2" d="M136,0c0,0-37,33-36,65s4.92,87.92,18,97l-3,5c0,0,43,3,48,45s74,44,90,77v11"/>
              <polyline id="fly5" className="st2" points="0,46 70,60 120,95 76,146 105,209 194,190 213,229 264,202 236,0  "/>
              <polyline id="fly4" className="st2" points="120,300 54,225 172,175 138,80 220,96 205,42 300,124   "/>
              <line id="fly3" className="st2" x1="0" y1="160" x2="300" y2="226"/>
              <line id="fly2" className="st2" x1="300" y1="92" x2="145" y2="300"/>
              <line id="fly1" className="st2" x1="92" y1="0" x2="13" y2="300"/>
            </g>
            <rect className="cls-1" x="50" y="50" width="200" height="200"/>
            <rect className="cls-2" x="100" y="100" width="100" height="100"/>
            <rect className="cls-3" width="300" height="300"/>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default GrowthPage

export const query = graphql`
query BugVideoQuery {
  bugpages: allMarkdownRemark(filter: {fields: {table: {eq: "growth"}}}) {
      edges {
        node {
          fields {
            path
            slug
          }
        }
      }
    }
}
`
