import React, {useState,useEffect} from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery,graphql } from "gatsby"

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function supplyRandomPage(slug,table,data){
  if(typeof localStorage === "undefined"){
    return "";
  }
  const allLinks = data.group.reduce((acc,g) => {
    acc[g.distinct[0]] = g.edges.map(e=>e.node.fields.slug)
    return acc;
  },{})
  if(typeof window === "undefined"){
    return allLinks[table][Math.floor(Math.random()*allLinks[table].length)];
  }
  let history = localStorage.bt_history ? JSON.parse(localStorage.bt_history) : allLinks;

  if(!table || !localStorage){
    return;
  }
  //if we have pages left, and its just the one we're on
  if(history[table] && history[table].length > 0 && history[table][0] !== slug){
    history[table] = history[table].filter(s => s !== slug);
  } else {
    history[table] = allLinks[table].filter(s => s !== slug);
    localStorage.bt_history = JSON.stringify(history);
  };
  let tableLinks = history[table];
  let suppliedPage = tableLinks[Math.floor(Math.random()*tableLinks.length)];
  return suppliedPage;
  // localStorage.bt_history = JSON.stringify(history);
  // window.location = `/${table}${nextPage}`;
}

export function supplyRandomMathMusicPage(slug,table,data, hidden){
  if(!hidden){
    hidden = [];
  }
  if(typeof localStorage === "undefined"){
    return "";
  }
  const allLinks = data
  let history = localStorage.bt_history ? JSON.parse(localStorage.bt_history) : {mathmusic: allLinks};
  console.log("mm history",history,allLinks)
  if(typeof window === "undefined"){
    return ""
  }
  if(!table){
    return;
  }
  //if we have pages left, and its just the one we're on
  if(history[table] && history[table].length > 0){
    console.log("testing 1",slug)
    history[table] = history[table].filter(s => s !== slug && hidden.indexOf(s) === -1);
    localStorage.bt_history = JSON.stringify(history);
  } else {
    console.log("testing 2",slug)
    history[table] = data.filter(s => s !== slug && hidden.indexOf(s) === -1);
    localStorage.bt_history = JSON.stringify(history);
  };
  let tableLinks = history[table];
  let suppliedPage = tableLinks[Math.floor(Math.random()*tableLinks.length)];
  return suppliedPage;
  // localStorage.bt_history = JSON.stringify(history);
  // window.location = `/${table}${nextPage}`;
}

export default function RandomPage(e,table,data){
  if(typeof localStorage === "undefined"){
    return;
  }
  e.preventDefault && e.preventDefault();
  let allLinks
  if(data[0] && typeof data[0] === "string"){
    allLinks = {};
    allLinks[table] = data
  } else {
    allLinks = data.group.reduce((acc,g) => {
      acc[g.distinct[0]] = g.edges.map(e=>e.node.fields.slug)
      return acc;
    },{})
  }
  let history = localStorage.bt_history ? JSON.parse(localStorage.bt_history) : allLinks;

  if(!table || !localStorage){
    return;
  }
  history[table] = history[table] && history[table].length > 0 ? shuffle(history[table]) : allLinks[table];
  let tableLinks = history[table];
  let nextPage = tableLinks.pop();
  localStorage.bt_history = JSON.stringify(history);
  window.location = `/${table}${nextPage}`;
}
